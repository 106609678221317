import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Image from 'gatsby-image';

import SEO from '../components/seo';

const AboutPage = () => {
  const data = useStaticQuery(graphql`
    query AboutQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.png/" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      site {
        siteMetadata {
          author
        }
      }
    }
  `);

  return (
    <>
      <SEO title="O mnie" />
      <>
        <h1
          style={{
            textAlign: 'center',
          }}
        >
          O mnie
        </h1>
        <Image
          fluid={data.avatar.childImageSharp.fluid}
          alt={data.site.siteMetadata.author}
          style={{
            marginBottom: 0,
            minWidth: 50,
            width: 'auto',
            maxHeight: '400px',
          }}
        />
        <p>
          Cześć! Mam na imie Józek. Programuję ponad dwa lata i lubię dzielić się wiedzą. W tym celu stworzyłem tę stronę - by stanowiła
          punkt zbiorczy dla moich szkoleń i prezentacji.
        </p>
        <p>Pracuję w pełni zdalnie i mieszkam w Katowicach.</p>
        <p>
          Jeśli chcesz się ze mną skontaktować zachęcam do wysłania maila na{' '}
          <a href="mailto:jozef.piecyk@gmail.com">jozef.piecyk@gmail.com</a>
        </p>
        <p>
          "Kiedyś" pojawią się też tutaj artykuły blogowe, ale do tego czasu możesz zerknąć czy nie zainteresuje Cię któraś{' '}
          <Link to="/lectures">prezentacja</Link> lub <Link to="workshops">szkolenie</Link>.
        </p>
      </>
    </>
  );
};

export default AboutPage;
